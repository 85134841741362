<template>
  <div>
    <div class="columns is-vcentered">
      <div class="column is-5 is-12-touch">
        <h1>{{ $t('home.headline') }}</h1>
        <p class="home-content__synopsis">{{ $t('home.synopsis') }}</p>
        <div class="has-text-centered">
          <b-button
              type="is-primary"
              size="is-medium"
              tag="router-link"
              :to="{ name:'incorporation' }"
          >
            {{ $t('home.incorporate') }}
          </b-button>
        </div>
      </div>
      <div class="column is-7 is-hidden-touch">
        <figure class="image is-4by3">
          <img src="/home.svg" alt="">
        </figure>
      </div>
    </div>
    <figure class="image is-hidden-desktop home-content__image">
      <img src="/home.svg" alt="">
    </figure>
  </div>
</template>

<script>
  export default {
    name: 'HomeContent'
  }
</script>
