<template>
  <section class="section">
    <div class="container">
      <home-content></home-content>
    </div>
  </section>
</template>

<script>
import HomeContent from '@/components/HomeContent';

export default {
  name: 'Home',
  components: { HomeContent },
  metaInfo () {
    return {
      title: this.$t('home.title'),
      meta: [
        { name: 'description', content: this.$t('home.description') }
      ]
    };
  }
};
</script>
